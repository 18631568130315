@import '../../css_variables/breakpoints';
@import '../../css_variables/color';
@import '../../css_variables/typography';

$header-height: 68px;
$members-medium-min: 760px;
$members-medium-max: 759px;
$members-xl-bp: 1080px;

.iconset {
  position: absolute;
  height: 0;
  width: 0;
  display: none;
}

.icon {
  width: 26px;
  height: 26px;
  fill: currentColor;
  display: inline-block;
  vertical-align: middle;
}

.icon--small {
  height: 12px;
  width: 12px;
  margin: 0 0 0 4px;
}

.italics {
  font-style: italic;
}

.new-header {
  background: $legacy-green;
  color: white;
  width: 100%;
  z-index: 50;
  height: $header-height;

  .inner {
    max-width: $members-xl-bp;
    width: 100%;
    padding: 16px 16px 6px;
  }

  a {
    display: inline-block;
    padding: 4px 0;
    font-size: 18px;
  }

  .header__search {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-right: 78px;

    input {
      -webkit-appearance: none;
      border-radius: 8px;
      margin: 16px 48px 0 24px;
      padding: 8px 14px;
      font-size: 13px;
      top: -11px;
      right: -67px;
      color: #444;
      width: 100%;
      border: none;
      border-bottom: 1px solid #DDD;
      box-shadow: inset 0 1px 2px #CCC;
      height: 34px;
      opacity: 1;
      -webkit-transition: all 300ms ease-out;
      transition: all 300ms ease-out;
      float: right;

      &:focus {
        border-color: $legacy-green-dark;

        & + button {
          color: $legacy-green-dark;
        }
      }
    }

    .search_button {
      -webkit-transition: -webkit-transform 200ms ease-out;
      transition: transform 200ms ease-out;
    }

    .search_button .icon {
      height: 28px;
      width: 28px;
      -webkit-transform: scale(0.57);
      -ms-transform: scale(0.57);
      transform: scale(0.57);
      -webkit-transform-origin: center;
      -ms-transform-origin: center;
      transform-origin: center;
      -webkit-transition: -webkit-transform 200ms ease-out;
      transition: transform 200ms ease-out;
    }

    @media (max-width: $members-medium-max) {
      input {
        opacity: 0;
        width: 0;
      }

      .search_button {
        -webkit-transform: translate(17px, 3px);
        -ms-transform: translate(17px, 3px);
        transform: translate(17px, 3px);

        .icon {
          color: white;
          -webkit-transform: none;
          -ms-transform: none;
          transform: none;
        }
      }
    }
  }

  > * {
    padding: 12px 16px 6px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: $header-height;
  }

  .donate-link {
    display: none;
  }

  @media (min-width: $members-medium-min) {
    margin-bottom: 45px;

    .inner {
      padding: 16px 16px 6px;
    }

    > * {
      padding: 12px 32px 6px;
    }

    .header__search {
      max-width: 225px;
      margin-right: 18px;

      input {
        margin-left: 20px;
      }
    }

    .donate-link {
      display: block;
      margin-left: 24px;
      line-height: 3;
      align-self: baseline;
      height: 37px;
      color: white;
      font-family: $font-family-serif;
      letter-spacing: 0.05em;
    }
  }

  @media (min-width: $members-medium-min) {
    .donate-link {
      line-height: 2.3;
      height: 34px;
    }
  }

  @media (min-width: 900px) {
    margin-bottom: 50px;

    .donate-link {
      top: 0;
    }
  }

  &.simple {
    margin-bottom: 0;
    border-bottom: 4px solid $discovery-gold;
  }

  .mobile-new-donation-toggle,
  .mobile-doe-toggle {
    top: 4px;
    right: 44px;
    color: white;

    > .icon {
      width: 30px;
      height: 30px;
      top: -2px;
    }

    &:hover,
    &:focus,
    &:active {
      color: white;
    }

    @media (min-width: $members-medium-min) {
      display: none;
    }
  }
}

@media (min-width: $members-medium-min) {
  .secondary-nav {
    display: block;

    a {
      padding: 14px 5px 11px 5px;
      font-size: 14px;
    }
  }
}

.strong {
  font-weight: bold;
}

.emph {
  font-style: italic;
}

.search_button {
  background: none;
  color: #A6A6A6;
  position: absolute;
  right: -30px;
  top: -6px;
  border: 0;
  box-shadow: none;

  .icon {
    height: 16px;
    width: 16px;
  }

  &:focus,
  &:hover,
  &:active {
    background: none;
    color: $legacy-green-dark;
  }
}

.fb-nav {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 16px 8px 12px;

  @media (max-width: $members-medium-max) {
    display: none;
    position: absolute;
    width: 100%;
    background: #E7EDF6;
    top: $header-height;
    padding: 0;
    z-index: 1000;
    text-align: center;

    &.visible {
      display: block;
    }

    &::before {
      position: absolute;
      content: '';
      height: 1px;
      width: 1px;
      top: 0;
      right: 20px;
      border-top: 10px solid $legacy-green;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }
  }
}

.simple .fb-nav::before {
  border-color: $discovery-gold transparent transparent;
}

.text-link {
  display: inline-block;
  padding: 0;
  line-height: 1;
  border-bottom: 1px solid rgba(0, 85, 149, 0.3);
  -webkit-transition: border-bottom-color 100ms;
  transition: border-bottom-color 100ms;
  margin-right: 5px;

  &.strong {
    padding-bottom: 0;
  }

  &:hover {
    border-color: rgba(0, 85, 149, 0.5);
  }

  @media (min-width: $members-medium-min) {
    border-color: rgba(255, 255, 255, 0.3);

    &:hover,
    &:focus {
      border-color: rgba(255, 255, 255, 0.5);
    }
  }
}

.fb-nav__list {
  font-family: $font-family-sans;
  top: 10px;

  a:link,
  a:visited {
    text-decoration: none;
    color: white;
  }

  list-style: none;

  li {
    padding: 0 4px;
    display: inline-block;
    vertical-align: middle;
  }

  #dashboard_nav_toggle {
    top: 3px;
  }

  .icon {
    width: 24px;
    margin: 0 1px;
  }

  @media (min-width: $members-medium-min) {
    .icon:hover {
      fill: #C9DDB4;
    }
  }

  @media (max-width: $members-medium-max) {
    top: 0;
    padding: 24px 0;
    margin: 0;
    border-bottom: 1px solid #CED1D6;
    font-size: 1.1em;

    a:link,
    a:visited {
      color: $legacy-blue;
    }
  }
}

.list-nav {
  top: 50px;
  left: 0;

  ul {
    list-style: none;
    margin: 0;
  }

  li {
    display: block;
    color: #7A7A7A;
    border-bottom: 1px solid #EEE;
    font-family: $font-family-sans;
    font-size: 0.9em;
    padding: 0;
    -webkit-transition: background-color 60ms linear;
    transition: background-color 60ms linear;

    .icon {
      float: left;
      height: 16px;
      width: 16px;
      margin: 1px 8px 0 2px;

      &::before {
        font-size: 14px;
      }
    }

    a:link,
    a:visited {
      padding: 8px;
      display: block;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5;
      color: #7A7A7A;
      -webkit-transition: color 50ms linear;
      transition: color 50ms linear;
    }

    a:hover {
      color: $legacy-blue;
    }
  }

  .hover-li li:hover {
    background-color: #EEE;
  }

  &.settings-nav {
    left: -162px;
  }

  &.dashboard-nav {
    left: auto;
    right: 10px;
    top: 43px;
    width: 250px;
  }

  &.dashboard-nav > ul > li {
    border-bottom: 0;
  }

  .dashboard-nav__heading {
    font-size: 14px;
    color: $legacy-blue;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
    padding: 5px 0 5px 8px;
    border-bottom: 1px solid #EEE;
  }

  .dashboard-nav__date::after {
    content: ' • ';
    font-size: 0.5em;
    vertical-align: middle;
    padding: 0 2px;
  }
}

.communicate-nav,
.donation-nav {
  width: 290px;

  li {
    overflow: hidden;

    &:hover {
      background-color: #EEE;
    }

    a:link,
    a:visited {
      padding: 12px 16px;
      font-size: 13px;
      line-height: 1;

      .donation-nav__amount {
        color: $legacy-blue;
        font-weight: bold;
      }
    }

    &.communicate-nav__more-actions,
    &.donation-nav__more-actions {
      &:hover {
        background-color: white;
      }

      padding: 10px 0;
      text-align: center;

      a:link,
      a:visited {
        display: inline;
        font-size: 12px;
        font-weight: bold;
        padding: 0 3px;
      }

      a:hover {
        color: lighten($legacy-blue, 10%);
      }
    }
  }
}

.overlay.donation-nav {
  a:link,
  a:visited {
    color: #777;
  }

  a.donation-nav__amount:link,
  a.donation-nav__amount:visited {
    color: $legacy-blue;
  }
}

.communicate-nav__author,
.donation-nav__donor {
  font-weight: bold;
}

.donation-nav__date {
  font-size: 11px;
  color: #AAA;
  display: block;
  margin-bottom: 2px;
  font-weight: normal;
}

.donation-nav__content {
  line-height: 1.3;
}

.communicate-nav__subject {
  font-weight: 400;
}

.communicate-nav__preview {
  margin-top: 7px;
  display: block;
  color: #9F9F9F;
  font-size: 12px;
  height: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

$ham-width: 32px;

.hamburger.hamburger {
  display: none;
  font-size: 32px;
  cursor: pointer;
  width: $ham-width;
  height: 20px;
  position: absolute;
  top: 23px;
  right: 16px;

  .line {
    display: block;
    background: white;
    width: $ham-width;
    height: 4px;
    position: absolute;
    left: 0;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }

  .newStuffBadge {
    top: -8px;
  }

  .line-1 {
    top: 0;
  }

  .line-2 {
    top: 50%;
  }

  .line-3 {
    top: 100%;
  }

  &.active {
    .line-1 {
      -webkit-transform: translateY(10px) translateX(0) rotate(45deg);
      -ms-transform: translateY(10px) translateX(0) rotate(45deg);
      transform: translateY(10px) translateX(0) rotate(45deg);
    }

    .line-2 {
      opacity: 0;
    }

    .line-3 {
      -webkit-transform: translateY(-10px) translateX(0) rotate(-45deg);
      -ms-transform: translateY(-10px) translateX(0) rotate(-45deg);
      transform: translateY(-10px) translateX(0) rotate(-45deg);
    }

    .newStuffBadge {
      opacity: 0;
    }
  }

  @media (max-width: $members-medium-max) {
    display: block;
  }
}

.overlay {
  position: absolute;
  box-shadow: 0 0 4px rgba(100, 100, 100, 0.4);
  min-width: 200px;
  background: white;
  color: #222;
  z-index: 5;
  display: none;

  ul {
    background: white;
  }

  &::after {
    content: '';
    position: absolute;
    top: -9px;
    right: 6%;
    margin-left: -5px;
    border-right: 10px solid white;
    border-top: 10px solid white;
    border-left: 10px solid transparent;
    border-bottom: 10px solid transparent;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background: white;
    z-index: -1;
  }
}

.overlay.overlay--large {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  z-index: 100;
  text-align: left;
  padding: 8px;
  overflow-y: auto;

  > * {
    padding-bottom: 64px;
  }
}

.overlay--shown {
  display: block;
}

.overlay__close.overlay__close:link,
.overlay__close.overlay__close:visited {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10005;
  color: white;
  border-bottom: medium none;
  text-align: center;
  padding: 6px 12px;
  font-weight: bold;
  font-size: 22px;
  background: rgba(0, 0, 0, 0.7);
  line-height: 1;
  height: 40px;
  width: 40px;
  border-bottom-left-radius: 16px;

  &.show {
    display: block;
  }
}

@media (min-width: $members-medium-min) {
  .fb-nav__list {
    display: inline-block;
  }

  .profileNav {
    display: none;
  }

  .donationNav {
    display: none;
  }

  .nav-open {
    overflow: auto;
  }
}

.donationNav li {
  font-family: $font-family-serif;

  a:link,
  a:visited {
    color: $legacy-green;
    text-decoration: underline;
  }
}

.newStuffBadge {
  position: absolute;
  top: -1px;
  right: -5px;
  background: $sbf-red;
  text-indent: 0;
  color: white;
  text-align: center;
  background-clip: padding-box;
  font-size: 11px;
  font-weight: 900;
  line-height: normal;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
  padding: 2px 4px;
  //display: inline-block; Uncomment this when functionality is in place
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 4px 0;

  // Hiding these until we have the functionality in place
  display: none;
}

// DOE stuff

.new-donation-nav,
.doe-nav,
.doe-nav-1 {
  right: 3px;
  left: auto;
  max-width: 275px;
  z-index: 10000;

  &.dark {
    background: #F4EFEB;
    width: 275px;
    padding: 22px;
    border-radius: 10px;
    right: -8px;
    box-shadow: 0 8px 8px 0 rgb(53 53 53 / 30%);

    &::after {
      border-color: #F4EFEB #F4EFEB transparent transparent;
      background: inherit;
    }
  }

  h4 {
    font-size: 18px;
    margin: 0 0 8px 0;
    letter-spacing: normal;
  }

  p {
    margin: 0;
    font-size: 14px;
    font-family: $font-family-sans;
    line-height: 1.5;
    letter-spacing: normal;
  }

  li:hover {
    background: #EEE;
  }

  .dashboard-nav__heading:hover {
    background: white;
  }

  li a:link,
  li a:visited {
    font-size: 12px;
  }
}

.doe-nav.overlay--shown,
.new-donation--desktop.overlay.overlay--shown {
  display: none;

  @media (min-width: 760px) {
    display: block;
  }
}

.new-donation-nav {
  &.dark {
    right: -9px;
  }
}

.doe-nav-1 {
  top: 70px;
  right: 90px;
  width: 260px;

  &.dark {
    width: 260px;
    right: 90px;
  }
}

.doe-nav-1.overlay--shown,
.new-donation--mobile.overlay.overlay--shown {
  display: block;

  @media (min-width: 760px) {
    display: none;
  }
}

.new-donation--mobile.dark {
  top: 70px;
  right: 48px;
}

.doe-nav-toggle {
  margin-right: 1px;
}

@media (max-width: $members-medium-max) {
  .no-mobile {
    display: none !important;
  }
}

.list-nav .doe-close:link,
.list-nav .doe-close:visited,
.list-nav .new-donation-close:link,
.list-nav .new-donation-close:visited {
  position: absolute;
  top: 0;
  right: 14px;
  color: #353535;
  font-size: 18px;
  padding: 8px;
}

.new-donation-badge,
.doe-badge {
  background: none;
  box-shadow: none;
  height: 24px;
  width: 100%;
  line-height: 1.7;
  right: -11px;
  top: -4px;
  z-index: 1;
  display: block;

  .icon {
    position: absolute;
    top: 0;
    right: 0;
    height: 23px;
    width: 30px;
    color: $legacy-blue;
    z-index: -1;

    @media (min-width: $members-medium-min) {
      width: 24px;
    }
  }
}

.doe-nav-toggle-1 {
  margin-left: 4px;
  min-width: 30px;
}

.mobile-new-donation-toggle {
  margin-left: 10px;
  min-width: 30px;
}

.login {
  text-decoration: none;
}

.loggedin {
  header {
    background: $legacy-green;
    // A fake background, so that it's positioned above the actual menu
    &::before {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: $legacy-green;
    }
  }

  .login {
    &::after {
      content: '\25B6';
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      display: inline-block;
      margin: 0 0 0 8px;
    }
  }
}

.logo-img {
  position: relative;
  width: 37px;
  height: 33px;
  min-width: 37px;
}

.primary-nav.new-nav {
  position: absolute;
  top: 304px;
  z-index: 30;
  display: none;
  text-align: center;

  @media (max-width: $members-medium-max) {
    &.visible {
      display: block;
      height: calc(100%);
    }
  }

  a {
    padding: 12px 20px;
  }

  li.current a::after {
    bottom: -2px;
  }

  li:hover a::after {
    bottom: -2px;
  }

  li ul {
    @media (min-width: 760px) {
      top: 100%;
    }
  }

  @media (min-width: $members-medium-min) {
    display: block;
    position: absolute;
    top: $header-height;
    border-bottom: 3px solid $discovery-gold;
    z-index: 40;
    background: $legacy-green;

    .current ul {
      text-align: left;
    }
  }

  @media (max-width: $members-medium-max) {
    li {
      background: #CFDBE5;
      border-top: 1px solid;
      border-color: #CED1D6;
      padding: 6px 0;
      display: block;

      &::before {
        display: none;
      }

      &:hover a {
        color: $legacy-blue;

        &::after {
          display: none;
        }
      }
    }

    a {
      color: $legacy-blue;
    }

    .nav {
      font-size: 1em;
    }

    li ul a:hover {
      background: none;
    }
  }
}

.new-header.search {
  .logo-img {
    width: 47px;
    height: 43px;
    min-width: 47px;
    top: -5px;
  }
}

.small-hidden {
  display: none;

  @media (min-width: $members-medium-min) {
    display: inline;
  }
}

.hide-donate {
  .new-header {
    margin-bottom: 0;
  }
}

.new-search {
  background: white;
  padding: 20px 16px 8px;
  font-size: 0.7rem;
  margin: 0;

  h1 {
    line-height: 0.8;
    font-size: 1.5em;
    max-width: $large-minwidth;
    margin: 0 auto;
  }

  .need-help {
    color: $legacy-green;
    max-width: $large-minwidth;
    margin: 0 auto;
    display: block;

    a:link,
    a:visited {
      color: inherit;
    }
  }

  @media (min-width: $members-medium-min) {
    font-size: 0.9rem;
  }
}

.adjust-huener-before {
  &::before {
    content: '';
    display: inline-block;
    -webkit-animation: adjust-hue 1s infinite steps(8);
    animation: adjust-hue 1s infinite steps(8);
    transform-origin: 50% 50%;
    position: relative;
    left: -18px;
    top: -4px;
    // generated with our sass mixin in New Memberes
    width: 2px;
    height: 2px;
    box-shadow:
      0 4px #555,
      4px 0 #A2A2A2,
      0 -4px #D4D4D4,
      -4px 0 #F3F3F3,
      0 5px #555,
      5px 0 #A2A2A2,
      0 -5px #D4D4D4,
      -5px 0 #F3F3F3,
      0 6px #555,
      6px 0 #A2A2A2,
      0 -6px #D4D4D4,
      -6px 0 #F3F3F3,
      0 7px #555,
      7px 0 #A2A2A2,
      0 -7px #D4D4D4,
      -7px 0 #F3F3F3,
      0 8px #555,
      8px 0 #A2A2A2,
      0 -8px #D4D4D4,
      -8px 0 #F3F3F3,
      0 9px #555,
      9px 0 #A2A2A2,
      0 -9px #D4D4D4,
      -9px 0 #F3F3F3,
      0 4px #555,
      4px 0 #A2A2A2,
      0 -4px #D4D4D4,
      -4px 0 #F3F3F3,
      0 5px #555,
      5px 0 #A2A2A2,
      0 -5px #D4D4D4,
      -5px 0 #F3F3F3,
      0 6px #555,
      6px 0 #A2A2A2,
      0 -6px #D4D4D4,
      -6px 0 #F3F3F3,
      0 7px #555,
      7px 0 #A2A2A2,
      0 -7px #D4D4D4,
      -7px 0 #F3F3F3,
      0 8px #555,
      8px 0 #A2A2A2,
      0 -8px #D4D4D4,
      -8px 0 #F3F3F3,
      0 9px #555,
      9px 0 #A2A2A2,
      0 -9px #D4D4D4,
      -9px 0 #F3F3F3,
      0 4px #555,
      4px 0 #A2A2A2,
      0 -4px #D4D4D4,
      -4px 0 #F3F3F3,
      0 5px #555,
      5px 0 #A2A2A2,
      0 -5px #D4D4D4,
      -5px 0 #F3F3F3,
      0 6px #555,
      6px 0 #A2A2A2,
      0 -6px #D4D4D4,
      -6px 0 #F3F3F3,
      0 7px #555,
      7px 0 #A2A2A2,
      0 -7px #D4D4D4,
      -7px 0 #F3F3F3,
      0 8px #555,
      8px 0 #A2A2A2,
      0 -8px #D4D4D4,
      -8px 0 #F3F3F3,
      0 9px #555,
      9px 0 #A2A2A2,
      0 -9px #D4D4D4,
      -9px 0 #F3F3F3,
      0 4px #555,
      4px 0 #A2A2A2,
      0 -4px #D4D4D4,
      -4px 0 #F3F3F3,
      0 5px #555,
      5px 0 #A2A2A2,
      0 -5px #D4D4D4,
      -5px 0 #F3F3F3,
      0 6px #555,
      6px 0 #A2A2A2,
      0 -6px #D4D4D4,
      -6px 0 #F3F3F3,
      0 7px #555,
      7px 0 #A2A2A2,
      0 -7px #D4D4D4,
      -7px 0 #F3F3F3,
      0 8px #555,
      8px 0 #A2A2A2,
      0 -8px #D4D4D4,
      -8px 0 #F3F3F3,
      0 9px #555,
      9px 0 #A2A2A2,
      0 -9px #D4D4D4,
      -9px 0 #F3F3F3,
      3px 3px #888,
      -3px -3px #EEE,
      3px -3px #BBB,
      -3px 3px #FBFBFB,
      4px 4px #888,
      -4px -4px #EEE,
      4px -4px #BBB,
      -4px 4px #FBFBFB,
      5px 5px #888,
      -5px -5px #EEE,
      5px -5px #BBB,
      -5px 5px #FBFBFB,
      6px 6px #888,
      -6px -6px #EEE,
      6px -6px #BBB,
      -6px 6px #FBFBFB;
  }
}

@-webkit-keyframes adjust-hue {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes adjust-hue {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

// Beginning navigation hacks.
// I'm doing something horrible and hacky below, because we're supporting both the old nav and the new nav for now.
// Once we kill the old nav the markup should be restructured, and this hack can go away.

@media (max-width: $members-medium-max) {
  .nav-open {
    overflow: auto;
    background: #E7EDF6;

    body {
      overflow: hidden;
      height: 552px;
    }
  }

  .donationNav {
    height: 80px;
  }
}
